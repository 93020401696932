<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-3 col-12">Institution Collage</div>
    <div
      class="
        col-lg-9 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      "
    >
      <div
        class="input-group custom-form-group mb-0"
        :style="{ width: '180px' }"
      >
        <input
          type="text"
          v-model="addvotermobile"
          class="form-control"
          placeholder="Enter Mobile Number"
          aria-label="Search"
          aria-describedby="basic-addon1"
          autocomplete="off"
          maxlength="10"
          @keypress="onlyNumber"
        />
      </div>
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        :disabled="addvotermobile.length != 10"
        @click="addNewVoter()"
        :style="{ width: '122px' }"
      >
        <span v-if="!addvotershowloader">Add New Voter</span>
        <div
          class="spinner-border text-light custom-spinner-loader-btn"
          role="status"
          v-if="addvotershowloader"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        @click="sendBulkWhatsAppModal()"
        :disabled="selectedvoter == null || selectedvoter == ''"
      >
        Bulk WhatsApp
      </button>
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        @click="sendBulkCallModal()"
        :disabled="selectedvoter == null || selectedvoter == ''"
      >
        Bulk Calls
      </button>
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        @click="importVotersModal()"
      >
        Import Voters
      </button>
      <button
        type="button"
        @click="voterFilterModalOpen()"
        class="header-filter-btn-outer btn btn-light"
        title="Filter"
      >
        <span class="custom-filter-apply-alert"></span>
        <i class="fa fa-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
      <div class="company-section-outer">
        <div class="layout-content-section">
          <div class="custom-ultima-datatable custom-voter-datable" style="height: calc(100vh - 147px)">
            <DataTable
              :value="messageList"
              :scrollable="true"
              columnResizeMode="fit"
              scrollHeight="flex"
              :paginator="true"
              :rows="30"
              :totalRecords="totalRecords"
              @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :lazy="true"
              dataKey="lk1"
              v-model:selection="selectedvoter"
              :loading="loading"
            >
              <template #empty>No Records found.</template>
              <template #loading>Loading data. Please wait...</template>
              <Column headerStyle="width: 3%" bodyStyle="width: 3%" class="justify-content-center" selectionMode="multiple"></Column>
              <Column header="Select All" headerStyle="width: 96%" bodyStyle="width: 96%">
                <template #body="{ data }">
                  <div class="company-detail-box-outer mb-3">
                    <div class="row g-2">
                      <div
                        class="
                          col-lg-2 col-md-2 col-12
                          voter-profile-col
                          d-flex
                          align-items-center
                        "
                      >
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img
                                v-if="data.lk28 == null || data.lk28 == ''"
                                src="/assets/images/avatar.png"
                                class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                "
                                alt=""
                              />
                              <img
                                v-if="data.lk28 != null && data.lk28 != ''"
                                :src="data.lk28"
                                class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                "
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Voter Name</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.lk17 ? data.lk17 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Gender | Blood Group</label
                              >
                              <div class="from-label-value text-capitalize">
                                {{
                                  data.lk24
                                    ? data.lk24 == 1
                                      ? "Male"
                                      : data.lk24 == 2
                                      ? "Female"
                                      : "Others"
                                    : "N/A"
                                }}
                                | {{ data.lk20 ? data.lk20 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Vidhan Sabha</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.lk38 ? data.lk38 : "N/A" }}
                                <span v-if="data.lk39">({{ data.lk39 }})</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Date of Birth | Age</label
                              >
                              <div class="from-label-value">
                                {{ data.lk21 ? data.lk21 : "N/A" }} |
                                {{ data.lk22 ? data.lk22 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Voter Id | Part Number</label
                              >
                              <div class="from-label-value">
                                {{ data.lk26 ? data.lk26 : "N/A" }} |
                                {{ data.lk27 ? data.lk27 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Lok Sabha</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.lk40 ? data.lk40 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Profession | Designation</label
                              >
                              <div class="from-label-value text-capitalize">
                                {{ data.lk30 ? data.lk30 : "N/A" }} |
                                {{ data.lk36 ? data.lk36 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Area | Pin Code</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.lk18 ? data.lk18 : "N/A" }} |
                                {{ data.lk19 ? data.lk19 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Specification</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.lk37 ? data.lk37 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Email</label>
                              <div class="from-label-value">
                                {{ data.lk23 ? data.lk23 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div
                              class="
                                customer-label-group
                                mb-2
                                d-flex
                                justify-content-between
                              "
                            >
                              <div>
                                <label class="form-label">Mobile</label>
                                <div class="from-label-value">
                                  +91-{{ data.first3digit }}XXXX{{
                                    data.last3digit
                                  }}
                                </div>
                              </div>
                              <div class="d-flex align-items-end">
                                <button
                                  class="btn custom-outline-call-btn mx-2"
                                  @click="calltriggertovoter(data.lk3)"
                                  :disabled="callstatus == 1"
                                >
                                  <i
                                    class="fas fa-phone-alt call-btn-color"
                                  ></i></button
                                >
                                <button
                                  type="button"
                                  title="View Details"
                                  class="btn custom-outline-view-btn me-2"
                                  @click="
                                    customerdetailModalOpen(data.lk1, data.lk3)
                                  "
                                >
                                  <i class="fas fa-plus call-btn-color"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
  <div class="modal-mask" v-if="hierarchymodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Family Hierarchy</h5>
          <button
            type="button"
            class="btn-close"
            @click="closerhierarchymodal()"
          ></button>
        </div>
        <OrganizationChart />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="engagementmodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Engagements</h5>
          <button
            type="button"
            class="btn-close"
            @click="closevoterengagementmodal()"
          ></button>
        </div>
        <EngagementTotalVoterModal />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="maintenermodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Influencers</h5>
          <button
            type="button"
            class="btn-close"
            @click="closevotermaintenermodal()"
          ></button>
        </div>
        <MaintenerTotalVoterModal />
      </div>
    </div>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="voterfiltermodalsflag">
    <div
      class="
        modal-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By Voters</h5>
          <button
            type="button"
            class="btn-close"
            @click="voterFilterModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Voter Name</label>
                    <input
                      type="text"
                      v-model="lk17"
                      class="form-control"
                      placeholder="Enter Voter Name"
                      maxlength="30"
                      @keypress="isLetterWithSpace($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile Number</label>
                    <input
                      type="text"
                      v-model="lk3"
                      class="form-control"
                      placeholder="Enter Mobile Number"
                      maxlength="10"
                      @keypress="onlyNumber"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Email Id</label>
                    <input
                      type="text"
                      v-model="lk23"
                      class="form-control"
                      placeholder="Enter Email Id"
                      maxlength="30"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Profession</label>
                    <Multiselect
                      v-model="lk25"
                      :options="voterprofessionList"
                      :searchable="true"
                      label="label"
                      placeholder="Select Profession"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Blood Group</label>
                    <input
                      type="text"
                      v-model="lk20"
                      class="form-control"
                      placeholder="Enter Blood Group"
                      maxlength="6"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender</label>
                    <div class="custom-group-radio-box-btn">
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="lk24"
                        name="gender-option"
                        id="male"
                        autocomplete="off"
                        value="1"
                      />
                      <label
                        class="btn custom-radio-box-btn text-capitalize"
                        for="male"
                        >Male</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="lk24"
                        name="gender-option"
                        id="female"
                        autocomplete="off"
                        value="2"
                      />
                      <label
                        class="btn custom-radio-box-btn text-capitalize"
                        for="female"
                        >Female</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="lk24"
                        name="gender-option"
                        id="other"
                        autocomplete="off"
                        value="3"
                      />
                      <label class="btn text-capitalize" for="other"
                        >Other</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Age</label>
                    <input
                      type="text"
                      v-model="lk22"
                      class="form-control"
                      placeholder="Enter Age"
                      maxlength="2"
                      @keypress="onlyNumber"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pin Code</label>
                    <input
                      type="text"
                      v-model="lk19"
                      class="form-control"
                      placeholder="Enter Age"
                      maxlength="6"
                      @keypress="onlyNumber"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Area</label>
                    <input
                      type="text"
                      v-model="lk18"
                      class="form-control"
                      placeholder="Enter Area"
                      maxlength="20"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Voter Id</label>
                    <input
                      type="text"
                      v-model="lk26"
                      class="form-control"
                      placeholder="Enter Booth No"
                      maxlength="10"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button
                class="btn filter-apply-btn"
                @click="
                  btnFilterSearch(
                    lk17,
                    lk3,
                    lk23,
                    lk25,
                    lk20,
                    lk24,
                    lk22,
                    lk19,
                    lk18,
                    lk26
                  )
                "
              >
                Apply Filter
              </button>
              <button
                class="btn btn-link filter-reset-btn"
                @click="resetVoterFilter"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
  <!-- next action modal start here -->
  <div class="modal-mask" v-if="viewvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Voter Details</h5>
          <button
            type="button"
            class="btn-close"
            @click="voterDetailModalClose()"
          ></button>
        </div>
        <ul
          class="nav nav-tabs custom-customer-modal-tab"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="voter-idcard-tab"
              data-bs-toggle="tab"
              data-bs-target="#votercarddetails"
              type="button"
              role="tab"
              aria-controls="appointment-history"
              aria-selected="true"
              @click="getvoterdetails()"
            >
              Voter Id
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-aadharcard-tab"
              data-bs-toggle="tab"
              data-bs-target="#voteraadhardetails"
              type="button"
              role="tab"
              aria-controls="vehicle-details"
              aria-selected="false"
              @click="getaadhardetails()"
            >
            Aadhaar
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-pancard-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterpandetails"
              type="button"
              role="tab"
              aria-controls="customer-details"
              aria-selected="false"
              @click="getpandetails()"
            >
              PAN
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-driving-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterdrivingdetails"
              type="button"
              role="tab"
              aria-controls="contact-history"
              aria-selected="false"
              @click="getlicensedetails"
            >
              Driving Licence
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-schemes-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterschemesdetails"
              type="button"
              role="tab"
              aria-controls="customer-details"
              aria-selected="false"
              @click="selectedtab((e = 1))"
            >
              Schemes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-history-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterhistorydetails"
              type="button"
              role="tab"
              aria-controls="customer-details"
              aria-selected="false"
              @click="selectedtab((e = 2))"
            >
              History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-hierarchy-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterhierachydetails"
              type="button"
              role="tab"
              aria-controls="contact-history"
              aria-selected="false"
              @click="selectedtab((e = 5))"
            >
              Family Hierarchy
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-engagements-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterengagementsdetails"
              type="button"
              role="tab"
              aria-controls="contact-history"
              aria-selected="false"
              @click="selectedtab((e = 6))"
            >
              Engagements
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="voter-influencers-tab"
              data-bs-toggle="tab"
              data-bs-target="#voterinfluencersdetails"
              type="button"
              role="tab"
              aria-controls="contact-history"
              aria-selected="false"
              @click="selectedtab((e = 7))"
            >
              Influencers
            </button>
          </li>
        </ul>
        <div class="modal-body modal-body-scroll-outer">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="votercarddetails"
              role="tabpanel"
              aria-labelledby="voter-idcard-tab"
            >
              <div>
                <div v-if="voterdivdisplayflag == 1">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Voter Number</label>
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab3 ? voterTabDetails.ab3 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab6 ? voterTabDetails.ab6 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Last Updated</label>
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab12 ? voterTabDetails.ab12 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab4 ? voterTabDetails.ab4 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Part Number</label>
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab5 ? voterTabDetails.ab5 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Polling Station</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab7 ? voterTabDetails.ab7 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Age</label>
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab8 ? voterTabDetails.ab8 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab13
                              ? voterTabDetails.ab13 == 1
                                ? "Male"
                                : voterTabDetails.ab13 == 2
                                ? "Female"
                                : "Others"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab18 ? voterTabDetails.ab18 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">City</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab16 ? voterTabDetails.ab16 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab14 ? voterTabDetails.ab14 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pincode</label>
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab20 ? voterTabDetails.ab20 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Assembly Constituency</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab22 ? voterTabDetails.ab22 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label"
                          >Assembly Constituency Number</label
                        >
                        <div class="from-label-value">
                          {{
                            voterTabDetails.ab23 ? voterTabDetails.ab23 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label"
                          >Parliamentary Constituency</label
                        >
                        <div class="from-label-value text-capitalize">
                          {{
                            voterTabDetails.ab24 ? voterTabDetails.ab24 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="voterdivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formLastNameInput" class="form-label"
                              >Voter Id
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              v-model="votercardnumber"
                              class="form-control text-uppercase"
                              id="formLastNameInput"
                              placeholder="Enter Voter Id"
                              autocomplete="off"
                              maxlength="10"
                            />
                            <span
                              class="custom-error position-fixed"
                              v-if="voteriderr"
                            >
                              {{ voteriderr }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            d-flex
                            justify-content-start
                            align-items-end
                          "
                        >
                          <div class="custom-form-group">
                            <button
                              type="button"
                              class="header-btn-outer btn btn-primary"
                              @click="fetchvoteriddetails()"
                              :disabled="
                                errStatus || votercardnumber.length == 0
                              "
                              :style="{ width: '104px' }"
                            >
                              <span v-if="!showloader">Get Details</span>
                              <div
                                class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                "
                                role="status"
                                v-if="showloader"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="voteraadhardetails"
              role="tabpanel"
              aria-labelledby="voter-aadharcard-tab"
            >
              <div>
                <div v-if="aadhardivdisplayflag == 1">
                  <div class="row">
                    <div
                      class="col-lg-2 col-md-2 col-12 d-flex align-items-center"
                    >
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center">
                          <div>
                            <img
                              v-if="
                                AadharTabDetails.aa11 == null ||
                                AadharTabDetails.aa11 == ''
                              "
                              src="/assets/images/avatar.png"
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              width="90"
                            />
                            <img
                              :src="this.AadharTabDetails.aa11"
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              width="90"
                              v-if="
                                AadharTabDetails.aa11 != null ||
                                AadharTabDetails.aa11 != ''
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                AadharTabDetails.aa4
                                  ? AadharTabDetails.aa4
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Aadhaar Number</label>
                            <div class="from-label-value">
                              {{
                                AadharTabDetails.aa3
                                  ? AadharTabDetails.aa3
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Father's Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                AadharTabDetails.aa5
                                  ? AadharTabDetails.aa5
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Date of Birth</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa8 ? AadharTabDetails.aa8 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa17
                              ? AadharTabDetails.aa17 == 1
                                ? "Male"
                                : AadharTabDetails.aa17 == 2
                                ? "Female"
                                : "Others"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">House</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa24
                              ? AadharTabDetails.aa24
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Landmark</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa25
                              ? AadharTabDetails.aa25
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Street</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa23
                              ? AadharTabDetails.aa23
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Location</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa17
                              ? AadharTabDetails.aa17
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Post Office</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa15
                              ? AadharTabDetails.aa15
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa19
                              ? AadharTabDetails.aa19
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">City</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa16
                              ? AadharTabDetails.aa16
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Sub Dist</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa22
                              ? AadharTabDetails.aa22
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa14
                              ? AadharTabDetails.aa14
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Address</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa6 ? AadharTabDetails.aa6 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pincode</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa7 ? AadharTabDetails.aa7 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="aadhardivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formaadharcardinput" class="form-label"
                              >Aadhaar No
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              v-model="aadharcardnumber"
                              class="form-control text-uppercase"
                              id="formaadharcardinput"
                              placeholder="Enter Aadhaar No"
                              autocomplete="off"
                              maxlength="12"
                              @keypress="onlyNumber"
                              :disabled="displayMobilePopUp"
                            />
                            <span
                              class="custom-error position-fixed"
                              v-if="aadharerr"
                            >
                              {{ aadharerr }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="
                            col-lg-2 col-md-2 col-12
                            d-flex
                            justify-content-start
                            align-items-end
                          "
                          v-if="!displayMobilePopUp"
                        >
                          <div class="custom-form-group">
                            <button
                              type="button"
                              class="header-btn-outer btn btn-primary"
                              :style="{ width: '100px' }"
                              @click="sendAadharOtp()"
                              :disabled="aadharcardnumber.length != 12"
                            >
                              <span v-if="!showloader">Send OTP</span>
                              <div
                                class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                "
                                role="status"
                                v-if="showloader"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-lg-2 col-md-2 col-12"
                          v-if="displayMobilePopUp"
                        >
                          <div class="custom-form-group">
                            <label for="formaadharcardinput" class="form-label"
                              >OTP <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              v-model="aadharotp"
                              class="form-control otp-input-space"
                              id="formaadharcardinput"
                              placeholder="Enter OTP"
                              autocomplete="off"
                              maxlength="6"
                              minlength="6"
                              @keypress="onlyNumber"
                              :disabled="mobile_otp_status == 1"
                            />
                            <!-- <span class="custom-error" v-if="v$.mobileotp.$errors">
                  {{ v$.mobileotp.$errors.$message }}
                </span> -->
                            <span class="custom-error" v-if="mobileotperr">
                              {{ mobileotperr }}
                            </span>
                            <span
                              v-if="timerMobileStatus"
                              class="otp-counter-text position-fixed mt-2"
                              >{{ timerMobileInterval }}</span
                            >
                          </div>
                        </div>
                        <div
                          class="
                            col-lg-2 col-md-2 col-12
                            d-flex
                            justify-content-start
                            align-items-end
                          "
                          v-if="displayMobilePopUp"
                        >
                          <div class="custom-form-group">
                            <button
                              type="button"
                              class="header-btn-outer btn btn-success"
                              @click="verifyAadharOtp()"
                              :style="{ width: '80px' }"
                              :disabled="aadharotp.length != 6"
                            >
                              <span v-if="!showloader">Verify</span>
                              <div
                                class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                "
                                role="status"
                                v-if="showloader"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="voterpandetails"
              role="tabpanel"
              aria-labelledby="voter-pancard-tab"
            >
              <div>
                <div v-if="pandivdisplayflag == 1">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pan Number</label>
                        <div class="from-label-value">
                          {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value">
                          {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Category</label>
                        <div class="from-label-value">
                          {{
                            PanTabDetails.ad5 == 1
                              ? "Person"
                              : PanTabDetails.ad5 == 2
                              ? "Business"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="pandivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formpancardinput" class="form-label"
                              >Pan No <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              v-model="pancardnumber"
                              class="form-control text-uppercase"
                              id="formpancardinput"
                              placeholder="Enter Pan Card No"
                              autocomplete="off"
                              maxlength="10"
                            />
                            <span
                              class="custom-error position-fixed"
                              v-if="errpan"
                            >
                              {{ errpan }}
                            </span>
                            <span
                              class="custom-error position-fixed"
                              v-if="errmsg"
                            >
                              {{ errmsg }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            d-flex
                            justify-content-start
                            align-items-end
                          "
                        >
                          <div class="custom-form-group">
                            <button
                              type="button"
                              class="header-btn-outer btn btn-primary"
                              @click="getpancarddetails()"
                              :disabled="errStatus || pancardnumber.length == 0"
                              :style="{ width: '104px' }"
                            >
                              <span v-if="!showloader">Get Details</span>
                              <div
                                class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                "
                                role="status"
                                v-if="showloader"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="voterdrivingdetails"
              role="tabpanel"
              aria-labelledby="voter-driving-tab"
            >
              <EditDrivingDetailModalTabs />
            </div>
            <div
              class="tab-pane fade"
              id="voterschemesdetails"
              role="tabpanel"
              aria-labelledby="voter-schemes-tab"
            >
              <div v-if="selected_tab == 1"><VoterSchemeDetailModalTabs /></div>
            </div>
            <div
              class="tab-pane fade"
              id="voterhistorydetails"
              role="tabpanel"
              aria-labelledby="voter-history-tab"
            >
              <div v-if="selected_tab == 2">
                <VoterHistoryDetailsModalaTabs />
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="voterhierachydetails"
              role="tabpanel"
              aria-labelledby="voter-hierarchy-tab"
            >
              <div v-if="selected_tab == 5"><OrganizationChart /></div>
            </div>
            <div
              class="tab-pane fade"
              id="voterengagementsdetails"
              role="tabpanel"
              aria-labelledby="voter-engagements-tab"
            >
              <div v-if="selected_tab == 6"><EngagementTotalVoterModal /></div>
            </div>
            <div
              class="tab-pane fade"
              id="voterinfluencersdetails"
              role="tabpanel"
              aria-labelledby="voter-influencers-tab"
            >
              <div v-if="selected_tab == 7"><MaintenerTotalVoterModal /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- next action modal end here -->
  <!-- WhatsApp sms modal start here -->
  <div class="modal-mask" v-if="voterbulkwhatsappmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Bulk WhatsApp</h5>
          <button
            type="button"
            class="btn-close"
            @click="sendBulkWhatsAppModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Select Template <span class="text-danger">*</span></label>
                <Multiselect
                  v-model="whatsapptemplate"
                  :options="whatsapptemplateList"
                  :searchable="false"
                  label="label"
                  placeholder="Select Template"
                  class="multiselect-custom text-capitalize"
                  :canClear="true"
                  :closeOnSelect="true"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Campaign Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="campaignname"
                  class="form-control"
                  placeholder="Enter Campaign Name"
                  maxlength="30"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload Document</label>
                <FileUpload
                  mode="basic"
                  ref="importdocfile"
                  class="custom-upload-btn-ultima"
                  v-model="importdocfile"
                  v-on:change="handleUploadVoterDocument()"
                />
                <span class="custom-error" v-if="importmsg.file">
                  {{ importmsg.file }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button
              type="button"
              class="btn modal-bulk-next-btn"
              @click="assignbulkvoterwhatsapp()"
              :disabled="campaignname == '' || campaignname == null || whatsapptemplate == '' || whatsapptemplate == null"
            >
              <span v-if="!bulkwhatsapploader">Submit</span>
              <div
                class="spinner-border text-light custom-spinner-loader-btn"
                role="status"
                v-if="bulkwhatsapploader"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- WhatsApp sms modal end here -->
  <!-- bulk call modal start here -->
  <div class="modal-mask" v-if="voterbulkcallmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Bulk Calls</h5>
          <button
            type="button"
            class="btn-close"
            @click="sendBulkCallModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload Mp3 <span class="text-danger">*</span></label>
                <FileUpload
                  mode="basic"
                  ref="bulkuploadcall"
                  class="custom-upload-btn-ultima"
                  v-model="bulkuploadcall"
                  v-on:change="handleUploadtune()"
                />
                <span class="custom-error" v-if="msgtune.file">
                  {{ msgtune.file }}
                </span>
              </div>
            </div>
            <!-- @change="handleUploadtune()" -->
            <!-- <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Campaign Name</label>
                <input
                  type="text"
                  v-model="campaignnamecall"
                  class="form-control"
                  placeholder="Enter Campaign Name"
                  maxlength="30"
                  autocomplete="off"
                />
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button
              type="button"
              class="btn modal-bulk-next-btn"
              @click="assignbulkvotercall()"
              :disabled="msgtune.file != '' || bulkuploadcall == null || bulkuploadcall == ''"
            >
              <span v-if="!bulkcallloader">Submit</span>
              <div
                class="spinner-border text-light custom-spinner-loader-btn"
                role="status"
                v-if="bulkcallloader"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- bulk call modal end here -->
  <!-- import voters modal start here -->
  <div class="modal-mask" v-if="voterimportmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Import Voters</h5>
          <button
            type="button"
            class="btn-close"
            @click="importVotersModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload CSV File <span class="text-danger">*</span></label>
                <FileUpload
                  mode="basic"
                  ref="importvoterfile"
                  class="custom-upload-btn-ultima"
                  v-model="importvoterfile"
                  v-on:change="handleUploadVoter()"
                />
                <span class="custom-error" v-if="importmsg.file">
                  {{ importmsg.file }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button
              type="button"
              class="btn modal-bulk-next-btn"
              @click="importvoterfilebtn()"
              :disabled="importmsg.file != '' || importvoterfile == null || importvoterfile == ''"
            >
              <span v-if="!importfileloader">Submit</span>
              <div
                class="spinner-border text-light custom-spinner-loader-btn"
                role="status"
                v-if="importfileloader"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- import voters modal end here -->
</template>
<script>
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";

export default {
  inject: ["global"],
  data() {
    return {
      v$: useValidate(),
      rowId: "",
      mobile_number: "",
      AadharTabDetails: "",
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      ReportChartDetails: "",
      hierarchymodalstatus: false,
      engagementmodalstatus: false,
      maintenermodalstatus: false,
      voterfiltermodalsflag: false,
      voterbulkwhatsappmodalsflag: false,
      voterbulkcallmodalsflag: false,
      voterimportmodalsflag: false,

      lk17: "",
      lk3: "",
      lk23: "",
      lk25: "",
      voterprofessionList: [
        { label: "Student", value: 1 },
        { label: "Self Employed", value: 2 },
        { label: "Salaried", value: 3 },
        { label: "Business", value: 4 },
        { label: "Farmer", value: 5 },
        { label: "Retired", value: 6 },
        { label: "Housewife", value: 7 },
      ],
      lk20: "",
      lk24: "",
      lk22: "",
      lk19: "",
      lk18: "",
      lk27: "",
      lk26: "",

      messageList: [],
      totalRecords: 0,
      page_no: 0,
      selected_tab: 1,
      viewvoterdetailstatus: false,
      pancardnumber: "",
      aadharcardnumber: "",
      votercardnumber: "",
      drivingnumber: "",
      errpan: "",
      aadharerr: "",
      voteriderr: "",
      errdriving: "",
      errmsg: "",
      showloader: false,
      timerAadharInterval: null,
      timerAadharOtpStatus: false,
      aadhar_otp_status: 0,
      showloadermobileotp: false,
      aadharVerifyStatus: false,
      errStatus: false,
      selectedvoter: null,
      voterdivdisplayflag: 0,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      mobileVerifyStatus: false,

      whatsapptemplate: "",
      whatsapptemplateList: [],
      campaignname: "",
      bulkuploadcall: "",
      campaignnamecall: "",
      loading: false,
      addvotermobile: "",
      addvotershowloader: false,
      aadharotp: "",

      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",

      callstatus: 0,
      bulkwhatsapploader: false,
      bulkcallloader: false,
      msg: [],
      msgtune: [],
      
      importfileloader: false,
      importmsg: [],
      importvoterfile: "",

      voterdocumentsmodalsflag: false,
      doctemplate: "",
      doctemplateList: [],
      doccampaignname: "",
      importdocfile: "",
      docuploadloader: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
        aadharotp: {
          required: helpers.withMessage("Enter OTP", required),
          minLength: minLength(4),
          maxLength: maxLength(4),
        },
    };
  },
  watch: {
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getTemplatesList();
    this.getmeranetawhatsappmsgs({
      page_no: this.page_no,
      lk17: this.lk17,
      lk3: this.lk3,
      lk23: this.lk23,
      lk25: this.lk25,
      lk20: this.lk20,
      lk24: this.lk24,
      lk22: this.lk22,
      lk19: this.lk19,
      lk18: this.lk18,
      lk26: this.lk26,
    });
  },
  methods: {
    calltriggertovoter(ev) {
      this.callstatus = 1;
      this.ApiService.triggercalltomeraneta({ mobile_number: ev }).then(
        (items) => {
          if (items.success === true) {
            this.callstatus = 0;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.callstatus = 0;
          }
        }
      );
    },
    fetchvoteriddetails() {
      this.showloader = true;
      let fields = {};
      fields["lk3"] = this.mobile_number;
      fields["voter_id"] = this.votercardnumber;
      this.ApiService.getvoteriddetails(fields).then((items) => {
        if (items.success === true) {
          this.displayMobilePopUp = true;
          this.showloader = false;
          this.viewvoterdetailstatus = false;
        } else {
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showloader = false;
        }
      });
    },
    sendAadharOtp() {
      this.showloader = true;
      if (
        this.aadharcardnumber != "" &&
        this.aadharcardnumber != null &&
        this.aadharcardnumber.length == 12
      ) {
        let fields = {};
        fields["lk3"] = this.mobile_number;
        fields["aadhar_number"] = this.aadharcardnumber;
        this.ApiService.generateaadharotp(fields).then((items) => {
          if (items.success === true) {
            this.displayMobilePopUp = true;
            this.startMobileTimer();
            this.timerMobileStatus = true;
            this.aadharotp = "";
            this.showloader = false;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      } else {
        this.$toast.open({
          message: "Please enter valid aadhaar number",
          type: "warning",
          duration: 3000,
          position: "top-right",
        });
      }
    },
    verifyAadharOtp() {
      this.showloader = true;
      let fields = {};
      fields["lk3"] = this.mobile_number;
      fields["otp"] = this.aadharotp;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.showloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.viewvoterdetailstatus = false;
        } else {
          this.showloader = false;
          this.mobileotperr = items.message;
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getpancarddetails() {
      // this.showloader = true;
    },
    getdrivingdetails() {
      // this.showloader = true;
    },
    mobileotp(mobileval) {
      if (mobileval.length == 4) {
        this.verifyMobileOtp();
      }
    },
    verifyMobileOtp() {
      this.v$.aadharotp.$validate();
      let fields = {};
      fields["cb9_otp"] = this.aadharotp;
      if (!this.v$.aadharotp.$error) {
        this.showloadermobileotp = true;
        this.ApiService.verifymobileotp(fields).then((items) => {
          if (items.status === 200) {
            this.mobile_otp_status = 1;
            this.showloadermobileotp = false;
            this.timerMobileCount = 0;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.displayRegBtn = true;
            this.mobileVerifyStatus = true;
            if (
              this.mobileVerifyStatus == true &&
              this.emailVerifyStatus == true
            ) {
              clearInterval(this.timer);
            }
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloadermobileotp = false;
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      }
    },
    redirectfn() {
      this.$router.push("/voters/report");
    },
    getvoterdetails() {
      this.ApiService.getvoterdetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.records;
          this.voterdivdisplayflag = 1;
        } else {
          this.voterdivdisplayflag = 0;
        }
      });
    },
    getlicensedetails() {
      this.ApiService.getlicensedetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.records;
          this.drivingdivdisplayflag = 1;
        } else {
          this.drivingdivdisplayflag = 0;
        }
      });
    },
    getaadhardetails() {
      this.ApiService.getaadhardetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.records;
          this.aadhardivdisplayflag = 1;
        } else {
          this.aadhardivdisplayflag = 0;
        }
      });
    },
    getpandetails() {
      this.ApiService.getpandetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.PanTabDetails = data.records;
          this.pandivdisplayflag = 1;
        } else {
          this.pandivdisplayflag = 0;
        }
      });
    },
    selectedtab(e) {
      this.selected_tab = e;
    },
    customerdetailModalOpen(e, mobile_number) {
      this.rowId = e;
      this.mobile_number = mobile_number;
      this.viewvoterdetailstatus = true;
      this.getvoterdetails();
      this.votercardnumber = "";
      this.aadharcardnumber = "";
      this.aadharotp = "";
      this.pancardnumber = "";
      this.drivingnumber = "";
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    voterFilterModalOpen() {
      this.voterfiltermodalsflag = true;
    },
    voterFilterModalClose() {
      this.voterfiltermodalsflag = false;
    },
    sendBulkWhatsAppModal() {
      this.voterbulkwhatsappmodalsflag = true;
      this.getTemplatesList();
    },
    sendBulkWhatsAppModalClose() {
      this.voterbulkwhatsappmodalsflag = false;
      this.whatsapptemplate = "";
      this.campaignname = "";
    },
    sendDocumentModal() {
      this.voterdocumentsmodalsflag = true;
      this.getTemplatesList();
    },
    sendDocumentModalClose() {
      this.voterdocumentsmodalsflag = false;
    },
    sendBulkCallModal() {
      this.voterbulkcallmodalsflag = true;
    },
    sendBulkCallModalClose() {
      this.voterbulkcallmodalsflag = false;
    },
    importVotersModal() {
      this.voterimportmodalsflag = true;
    },
    importVotersModalClose() {
      this.voterimportmodalsflag = false;
    },
    btnFilterSearch(lk17, lk3, lk23, lk25, lk20, lk24, lk22, lk19, lk18, lk26) {
      this.voterfiltermodalsflag = false;
      this.lk17 = lk17;
      this.lk3 = lk3;
      this.lk23 = lk23;
      this.lk25 = lk25;
      this.lk20 = lk20;
      this.lk24 = lk24;
      this.lk22 = lk22;
      this.lk19 = lk19;
      this.lk18 = lk18;
      this.lk26 = lk26;
      this.getmeranetawhatsappmsgs({
        page_no: this.page_no,
        lk17: this.lk17,
        lk3: this.lk3,
        lk23: this.lk23,
        lk25: this.lk25,
        lk20: this.lk20,
        lk24: this.lk24,
        lk22: this.lk22,
        lk19: this.lk19,
        lk18: this.lk18,
        lk26: this.lk26,
      });
    },
    resetVoterFilter() {
      this.lk17 = "";
      this.lk3 = "";
      this.lk23 = "";
      this.lk25 = "";
      this.lk20 = "";
      this.lk24 = "";
      this.lk22 = "";
      this.lk19 = "";
      this.lk18 = "";
      this.lk26 = "";
      this.getmeranetawhatsappmsgs();
      this.voterfiltermodalsflag = false;
    },
    getmeranetawhatsappmsgs(e) {
      this.ApiService.getmeranetawhatsappmsgs(e).then((data) => {
        if (data.success == true) {
          this.messageList = data.records;
          this.totalRecords = data.totalcount;
          this.loading = false;
        } else {
          this.messageList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },

    hierarchymodalopen() {
      this.hierarchymodalstatus = true;
    },
    closerhierarchymodal() {
      this.hierarchymodalstatus = false;
    },
    voterengagementmodalopen() {
      this.engagementmodalstatus = true;
    },
    closevoterengagementmodal() {
      this.engagementmodalstatus = false;
    },
    votermaintenermodalopen() {
      this.maintenermodalstatus = true;
    },
    closevotermaintenermodal() {
      this.maintenermodalstatus = false;
    },
    isLetterWithSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s.]+$/.test(char)) return true;
      else e.preventDefault();
    },

    changePage(event) {
      this.page_no = event.page;
      this.getmeranetawhatsappmsgs({ page_no: this.page_no });
    },
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },
    validateAadharCardNumber(e) {
      this.errStatus = false;
      if (!this.validAadharCard(e)) {
        this.aadharerr = "Please enter valid aadhaar number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.aadharerr = "";
        return true;
      }
    },

    validAadharCard: function (e) {
      var re = /([0-9]){12}$/;
      return re.test(e);
    },

    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },

    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },

    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
    addNewVoter() {
      this.addvotershowloader = true;
      let fields = {};
      fields["mobile_number"] = this.addvotermobile;
      this.ApiService.addvoter(fields).then((items) => {
        if (items.success === true) {
          this.addvotershowloader = false;
          this.addvotermobile = "";
        } else {
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.addvotershowloader = false;
        }
      });
    },
    assignbulkvoterwhatsapp() {
      this.bulkwhatsapploader = true;
      let vals = this.selectedvoter.map(function (item) {
        return item.lk1;
      });
      this.selectedvoters = vals;
      var formData = new FormData();
      formData.append("template_id", this.whatsapptemplate);
      formData.append("campaign_name", this.campaignname);
      formData.append("voters", JSON.stringify(this.selectedvoters));
      formData.append("document", this.importdocfile);
      this.ApiService.savecampaign(formData).then((data) => {
          if (data.success == true) {
            this.bulkwhatsapploader = false;
            this.voterbulkwhatsappmodalsflag = false;
            this.whatsapptemplate = '';
            this.campaignname = '';
            this.importdocfile = '';
          }
        });
    },
    assignbulkvotercall() {
      this.bulkcallloader = true;
      let vals = this.selectedvoter.map(function (item) {
        return item.lk3;
      });
      this.selectedvoters = vals;
      var formData = new FormData();
      formData.append("tunefile", this.bulkuploadcall);
      formData.append("mobile_numbers", JSON.stringify(this.selectedvoters));
        this.ApiService.bulkcall(formData).then((data) => {
          if (data.success == true) {
            this.bulkcallloader = false;
          this.voterbulkcallmodalsflag = false;
          }
        });
    },
    handleUploadtune() {
      this.bulkuploadcall = this.$refs.bulkuploadcall.files[0];
      var file = this.$refs.bulkuploadcall.files[0];
      let allowedExtensions = /(\.mp3)$/i;
      if (!file) {
        this.msgtune["file"] = "Please upload mp3 tune.";
      } else if (!file.size > 5048) {
        this.msgtune["file"] = "File size cannot exceed more than 5MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.msgtune["file"] = "Invalid file type";
      } else {
        this.msgtune["file"] = "";
      }
    },
    importvoterfilebtn() {
      this.importfileloader = true;
      var formData = new FormData();
      formData.append("importfile", this.importvoterfile);
        this.ApiService.importvoters(formData).then((data) => {
          if (data.success == true) {
            this.importfileloader = false;
            this.voterimportmodalsflag = false;
            this.importvoterfile = '';
          }
        });
    },
    handleUploadVoter() {
      this.importvoterfile = this.$refs.importvoterfile.files[0];
      var file = this.$refs.importvoterfile.files[0];
      let allowedExtensions = /(\.csv)$/i;
      if (!file) {
        this.importmsg["file"] = "Please upload CSV excel file.";
      } else if (!file.size > 2048) {
        this.importmsg["file"] = "File size cannot exceed more than 2MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.importmsg["file"] = "Invalid file type";
      } else {
        this.importmsg["file"] = "";
      }
    },
    handleUploadVoterDocument() {
      this.importdocfile = this.$refs.importdocfile.files[0];
      var file = this.$refs.importdocfile.files[0];
      let allowedExtensions = /(\.pdf)$/i;
      if (!file) {
        this.importmsg["file"] = "Please upload document.";
      } else if (!file.size > 2048) {
        this.importmsg["file"] = "File size cannot exceed more than 2MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.importmsg["file"] = "Invalid file type";
      } else {
        this.importmsg["file"] = "";
      }
    },
    getTemplatesList() {
      this.ApiService.campaigntemplates().then((data) => {
        if (data.success == true) {
          this.whatsapptemplateList = data.records;
          this.doctemplateList = data.records;
        }
      });
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 20px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
.voter-report-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-top: 0;
}
.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}
.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}
.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}
.modal-bulk-next-btn:focus {
  box-shadow: none;
}
</style>